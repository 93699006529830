<template>
  <div class="row__dropdown" ref="dropdown">
    <div
      class="row__dropdown-item row__dropdown-item--edit icon__edit"
      @click="onEdit()"
      ref="dropdownEdit"
    >
      {{ $t(`buttons.${"editBtn"}`) }} 
    </div>
    <div
      class="row__dropdown-item row__dropdown-item--delete icon__delete"
      @click="onDelete()"
      ref="dropdownDelete"
    >
      {{ $t(`buttons.${"deleteBtn"}`) }} 
    </div>
    <div
      v-if="isUserAction && (isActive == 1)"
      @click="onDisable()" 
      class="row__dropdown-item row__dropdown-item--disable icon__disable"
      ref="dropdownDisable"
    >
      {{ $t(`buttons.${"disable"}`) }}  
    </div>
    <div
      v-if="isUserAction && (isActive == 0)"
      @click="onEnable()"
      class="row__dropdown-item row__dropdown-item--enable icon__enable"
      ref="dropdownEnable"
    >
      {{ $t(`buttons.${"enable"}`) }}   
    </div> 
  </div>
</template>

<script>
export default {
  name: "RowActionDropdown",

  data(){
    return{
      isUserAction: false,
    }
  },

  props: {
    onEdit: {
      required: true,
      type: Function,
    },
    onDelete: {
      required: true,
      type: Function,
    },
    isActive: {
      required: false,
      type: Number,
    },
    onDisable: {
      required: false,
      type: Function
    },
    onEnable: {
      required: false,
      type: Function
    }
  },

  mounted() {
    this.isUserAction = false;

    document.addEventListener("click", this.checkIfClickedInside);
   
    if(this.$route.name == "Users"){
      this.isUserAction = true;
    }
  },

  destroyed() {
    this.isUserAction = false;

    document.removeEventListener("click", this.checkIfClickedInside);
  },

  methods: {
    checkIfClickedInside(event) {
      const { target } = event;
      const { dropdown, dropdownEdit, dropdownDelete, dropdownEnable, dropdownDisable } = this.$refs;
      if (
        target === dropdown.parentElement ||
        target === dropdownEdit ||
        target === dropdownDelete ||
        target === dropdownEnable ||  
        target === dropdownDisable
      ) {
        this.$emit("toggleDropdown", true);
      } else {
        this.$emit("toggleDropdown", false);
      }
    },
  },
};
</script>

<style></style>
