<template>
  <div
    class="multi"
    :class="{ 'multi--open': showDropdown }"
    ref="multi"
    tabindex="0"
    @focus="showDropdown = true"
  >
    <div
      class="multi__placeholder input input--default input--shadow"
      ref="multiPlaceholder"
    >
      {{ placeholder }}
    </div>
    <div
      class="multi__dropdown"
      v-if="showDropdown"
      :style="dropdownStyleProperties"
    >
      <input
        type="text"
        @focus="showDropdown = true"
        @focusout="showDropdown = false"
        tabindex="0"
        class="multi__search input input--default"
        v-model="searchKeywords"
      />
      <div class="multi__items">
        <div class="multi__item" v-for="item in filteredItems" :key="item.id">
          <div class="checkbox">
            <input
              type="checkbox"
              :id="item.id"
              class="checkbox__checkbox"
              @input="toggleItems(item)"
              :checked="selectedItems.find((i) => i.id === item.id)"
              tabindex="-1"
            />
            <label :for="item.id" class="checkbox__label"></label>
          </div>
          <label :for="item.id">{{
            customLabel ? customLabel(item) : item[label]
          }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiSelectDropdown",

  props: {
    items: {
      required: true,
      type: Array,
    },
    label: {
      required: false,
      type: String,
    },
    placeholder: {
      required: true,
      type: String,
    },
    customLabel: {
      required: false,
      type: Function,
    },
    reduce: {
      required: false,
      type: Function,
    },
    selected: {
      required: false,
      type: Array,
    },
  },

  data() {
    return {
      dropdownWidth: 0,
      dropdownX: 0,
      dropdownY: 0,
      showDropdown: false,
      searchKeywords: "",
      selectedItems: [],
    };
  },

  computed: {
    dropdownStyleProperties() {
      return {
        width: `${this.dropdownWidth}px`,
        left: `${this.dropdownX}px`,
        top: `${this.dropdownY}px`,
      };
    },

    filteredItems() {
      return this.items.filter((item) =>
        (!this.label ? this.customLabel(item) : item[this.label])
          .toLowerCase()
          .includes(this.searchKeywords.toLowerCase())
      );
    },
  },

  mounted() {
    this.dropdownWidth = this.$refs.multiPlaceholder.clientWidth;
    this.computeDropdownPosition();
    window.addEventListener("click", this.dropdownEvent);
    if (this.selected) {
      this.selectedItems = this.selected.map((i) =>
        this.items.find((item) => item.id === Number(i))
      );
    }
  },

  destroyed() {
    window.removeEventListener("click", this.dropdownEvent);
  },

  methods: {
    dropdownEvent(event) {
      if (
        [...this.$refs.multi.getElementsByTagName("*")].includes(event.target)
      ) {
        this.showDropdown = true;
      } else {
        this.showDropdown = false;
      }
    },
    computeDropdownPosition() {
      this.dropdownX = this.$refs.multiPlaceholder.getBoundingClientRect().x;
      this.dropdownY =
        this.$refs.multiPlaceholder.getBoundingClientRect().y + 45;
    },

    toggleItems(item) {
      const itemIndex = this.selectedItems.findIndex((i) => i.id === item.id);
      if (itemIndex === -1) {
        this.selectedItems = [...this.selectedItems, item];
      } else {
        this.selectedItems.splice(itemIndex, 1);
      }
      if (this.reduce) {
        const reducedItems = this.selectedItems.map((item) =>
          this.reduce(item)
        );
        this.$emit("input", reducedItems);
      } else {
        this.$emit("input", this.selectedItems);
      }
    },
  },
};
</script>

<style lang="scss" scoped>


.multi {
  &__dropdown {
    position: fixed;
    height: 205px;
    background: var(--bg-1);
    z-index: 99;
    box-shadow: 0px 9px 21px var(--shadow-12);
    border-radius: var(--radius-1);
    overflow: auto;
    padding: 10px; 
  }

  &__placeholder,
  &__item label {
    cursor: pointer;
  }

  &__placeholder {
    font-family: var(--muli-text-semi-bold);
    color: var(--color-9);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 24px;
      height: 24px;
      right: 10px;
      top: 7px;
      transition: all 0.3s ease;
      background: url("../assets/icons/default/Bold 2px-chevron_down.svg")
        no-repeat center;
    }
  }

  &__placeholder,
  &__item {
    display: flex;
    align-items: center;
  }

  &__search {
    height: 30px;
    width: 100%;
    border-radius: var(--radius-3);
    padding-left: 45px;
    background-image: url("../assets/icons/default/search_icon.svg");
    background-repeat: no-repeat;
    background-position: left 10px center;
  }

  &__item {
    margin: 10px 0;
    color: var(--text-2);
    font-size: var(--x-small);

    .checkbox {
      margin-right: 10px;
    }
  }

  &--open {
    .multi__placeholder::after {
      transform: rotateX(180deg);
    }
  }
}

[data-theme=dark] .multi{
  &__placeholder{
    box-shadow: none;
    border: 1px solid #46505B  ;
  }
}
</style>
